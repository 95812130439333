#show{
    display: flex;
    flex-direction: column;

    .team_valid_wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .show_search_wrapper{
        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: space-evenly;
        width: 100%;

        #show_search_btn{
            padding: 2px 20px 2px 20px;
            margin-left: 20px;
            margin-right: 20px;
        }

        #show_search_txt{
            width: 100%;
        }
    }

    .show_container{
        display: grid;
        grid-template-columns: 25vw 25vw 25vw;
        margin-top: 40px;
    }

    .show_card{
        border-radius: 5px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.1);
        width: 90%;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        overflow: hidden;
        margin-bottom: 50px;
        text-align: center;

        .show_image{
            width: 200px;
            height: 200px;
            align-self: center;
            border-radius: 5px;
        }

        .show_content{
            display: flex;
            flex-direction: column;
            padding: 20px 20px 30px 20px;
            justify-content: space-between;

            .show_buttons{
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                margin-top: 20px;
                
                .button{
                    &:nth-child(2){
                        margin-left: 20px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 750px) {
    #show{
        .team_valid_wrapper{
            font-size: 15px;
        }

        .show_container{
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .show_card{
            width: 95%;
        }
    }
}

@media screen and (min-width: 750px) and (max-width: 1080px) {
    #show{
        .show_container{
            display: grid;
            grid-template-columns: 35vw 35vw;
        }

        .show_card{
            width: 95%;
        }
    }
}