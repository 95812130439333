#customers{
    display: flex;
    flex-direction: column;

    .show_flex_container{
        height: 100px;
    }

    .customers_search_wrapper{
        display: flex;
        flex-direction: row;
        margin-top: 30px;

        #customers_search_txt{
            width: 100%;
        }

        #customers_search_btn{
            margin-left: 20px;
        }
    }

    .customers_add_new_wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        margin: 30px 0 20px 0;

        .customers_add_new_txt{
            margin-left: 5px;
        }

        .customers_new_admin_wrapper{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            width: 100%;
        }

        #customers_add_new{
            width: 100%;
            max-width: 100%;
            min-width: 90%;
            min-height: 20vh;
        }

        #customers_add_new_btn{
            margin-left: 20px;
        }
    }

    .customers_table{
        display: flex;
        flex-direction: column;

        .customers_row{
            display: grid;
            grid-template-columns: 50% 50%;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            margin-bottom: 10px;
            padding: 10px 40px 10px 40px;

            .customers_data{
                &:nth-child(2){
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }
            }

            .delete{
                padding: 5px;
                color: $white;
                border: 0;
                outline: none;
                cursor: pointer;
                border-radius: 3px;
            }
        }
    }
}

@media screen and (max-width: 750px) {
    #customers{
        .customers_add_new_wrapper{
            #customers_add_new{
                width: 75%;
                max-width: 75%;
                min-width: 75%;
                min-height: 20vh;
            }
        }

        .customers_table{
            .customers_row{
                padding: 10px 10px 10px 10px;

                .customers_data{
                    &:nth-child(1){
                        font-size: 0.6rem;
                    }

                    &:nth-child(2){
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
}