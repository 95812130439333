#notFound{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    h1{
        color: $light-gray-1;
        font-size: 5rem;
    }
}