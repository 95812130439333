$white: #ffffff;
$red: #d9534f;
$light-gray: hsl(236, 19%, 34%);
$light-gray-1: #C1C1C1;
$matte-black-1: #282828;
$matte-black-2: #353535;
$matte-black-4: #313131;
$matte-black-3: #434242;
$light-blue: #5680E9;
$light-purple: #8860D0;

@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&display=swap');

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    letter-spacing: 1px;
    user-select: none;
}

body, html{
    width: 100vw;
    height: auto;
    overflow-x: hidden;
    font-family: 'Nunito', sans-serif;
}

h1,h2,h3,h4,h5,h6,div,span{
    cursor: default;
}

a{
    text-decoration: none;
    color: #5680E9;
}

.container{
    margin-left: 20vw;
    width: 80vw;
    min-height: 100vh;
    padding: 50px;

    .show_flex_container{
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .show_liner_loading{
            width: 50%;
        }
    }
}

.input{
    border-radius: 5px;
    font-size: 16px;
    padding: 7px 7px 7px 10px;

    &[data-valid="true"]{
        border: 1px solid $light-gray-1;
    }
    
    &[data-valid="false"]{
        border: 1px solid $red;
    }

    &:focus{
        outline-color: $light-blue;
    }
}

.button{
    color: $white;
    padding: 10px 20px 10px 20px;
    border: 0;
    border-radius: 2px;
    background: $light-blue;
    cursor: pointer;
    font-size: 17px;
    font-weight: 500;
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.4);
    transition: all 0.1s ease;
    outline: none;

    &:active{
        transform: scale(0.95);
    }
}

.delete{
    background-color: $red;
}

.main_title{
    width: 100%;
    padding: 20px 0 20px 0;
    font-size: 40px;
}

.empty_image{
    width: 40%;
    margin-bottom: 50px;
}

@media screen and (max-width: 750px) {
    .container{
        margin-left: 0;
        width: 100vw;
        min-height: 92vh;
        margin-top: 8vh;
        padding: 50px 20px 50px 20px;

        .show_flex_container{
            width: 100vw;
        }
    }
}


@import './login';
@import './navbar';
@import './home';
@import './blogForm';
@import './projectForm';
@import './teamForm';
@import './show';
@import './review';
@import './users';
@import './customers';
@import './socialMedia';
@import './settings';
@import './deletePopup';
@import './notFound';