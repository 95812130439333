#TeamForm{

    #TeamForm-form-wrapper{
        width: 100%;

        .input{
            width: 70%;
        }
    }

    .TeamForm_input_wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin: 25px 0 25px 0;

        .TeamForm_title{
            margin-bottom: 10px;
        }

        #TeamForm-careerDescription, #TeamForm-description{
            height: 16vh;
            resize: none;
        }

        .display_tags_wrapper{
            display: grid;
            grid-template-columns: auto auto auto;
            list-style: none;
        }

        .TeamForm_tags_content_wrapper{
            display: flex;
            flex-direction: row;
            width: 100%;
            margin-top: 20px;

            .button{
                margin-left: 20px;
            }

            #TeamForm_skills_perecentage{
                width: 60%;
            }

            #TeamForm_skill_value{
                margin-left: 20px;
                margin-right: 0px;
            }
        }

        #TeamForm_display_tags{
            margin-top: 10px;

            .TeamForm_tag{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 5px 7px 5px 7px;
                color: $white;
                background: $light-blue;
                border-radius: 2px;
                margin-right: 10px;
                margin-bottom: 5px;

                .TeamForm_tag_exit{
                    cursor: pointer;
                    margin-left: 15px;
                }
            }
        }

        .TeamForm_image_wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;

            #TeamForm-image-label{

                &[data-active="true"]{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border: 1px dashed $light-gray-1;
                    cursor: pointer;
                    padding: 20px 10px 20px 10px;
                    width: 200px;
                    height: 140px;
                    overflow: hidden;
                    border-radius: 5px;
                    border-spacing: 5px;
                    word-wrap: break-word;
                    font-size: 16px;
                    color: $light-gray-1;

                    #TeamForm-label-icon{
                        font-size: 50px;
                    }
                }

                &[data-active="false"]{
                    display: none;
                }
            }

            #TeamForm-image-preview{
                &[data-active="true"]{
                    display: block;
                    margin-left: 40px;
                    max-height: 140px;
                    border-radius: 5px;
                }

                &[data-active="false"]{
                    display: none;
                }
            }
    
            #TeamForm-image{
                display: none;
            }
        }
        
    }
}

@media screen and (max-width: 750px){
    #TeamForm{
        #TeamForm-form-wrapper{
            .input{
                width: 100%;
            }
        }

        
        .TeamForm_input_wrapper{

            .display_tags_wrapper{
                display: flex;
                flex-direction: column;
            }

            .TeamForm_image_wrapper{
                #TeamForm-image-preview{
                    &[data-active="true"]{
                            margin-left: 0px;
                            margin-top: 10px;
                    }
    
                    &[data-active="false"]{
                        display: none;
                    }
                }

                flex-direction: column;
            }
        }
    }

}