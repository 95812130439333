#user{
    display: flex;
    flex-direction: column;

    .show_flex_container{
        height: 100px;
    }

    .user_search_wrapper{
        display: flex;
        flex-direction: row;
        margin-top: 30px;

        #user_search_txt{
            width: 100%;
        }

        #user_search_btn{
            margin-left: 20px;
        }
    }

    .user_add_new_wrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        margin: 30px 0 20px 0;

        .user_admin_email_add_new{
            display: flex;
            flex-direction: row;
            width: 100%;
        }

        .user_add_new_txt{
            margin-left: 5px;
        }

        .user_new_admin_wrapper{
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 100%;
        }

        #user_add_new{
            width: 40%;
        }

        #user_add_new_btn{
            margin-left: 20px;
        }
    }

    .user_table{
        display: flex;
        flex-direction: column;

        .user_row{
            display: grid;
            grid-template-columns: 50% 50%;
            box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
            margin-bottom: 10px;
            padding: 10px 40px 10px 40px;
            align-items: center;

            &[data-role="super admin"]{
                color: $white;
                background-color: $matte-black-3;
            }

            .user_data{
                &:nth-child(2){
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                }
            }

            .delete{
                padding: 5px;
                color: $white;
                border: 0;
                outline: none;
                cursor: pointer;
                border-radius: 3px;
            }
        }
    }
}

@media screen and (max-width: 750px) {
    #user{
        .user_add_new_wrapper{
            .user_new_admin_wrapper{
                flex-direction: column;
                align-items: flex-start;
            }
            #user_add_new{
                width: 65%;
            }
        }

        .user_table{
            .user_row{
                padding: 10px 10px 10px 10px;

                .user_data{
                    &:nth-child(1){
                        font-size: 0.6rem;
                    }
                    
                    &:nth-child(2){
                        display: flex;
                        flex-direction: row;
                        justify-content: flex-end;
                    }
                }
            }
        }
    }
}