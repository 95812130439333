#home{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h1{
        font-size: 3rem;
        color: $light-gray-1;
    }
}

@media screen and (max-width: 750px) {
    #home{    
        h1{
            font-size: 2rem;
        }
    }
}