#review{

    .review_container{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }

    .review_card{
        border-radius: 5px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
        display: flex;
        flex-direction: row;
        padding: 10px;
        color: $matte-black-3;
        margin-bottom: 30px;

        .review_relate_container{
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            border: 2px solid $light-gray-1;
            border-radius: 5px;
            width: 50%;
            text-align: center;

            .review_image{
                width: 100%;
                border-radius: 5px;
            }
            
            .review_title{
                margin-top: 10px;
            }
        }

        .review_content{
            padding: 20px 30px 20px 30px;
            display: flex;
            flex-direction: column;
            width: 50%;
            height: auto;

            .review_email{
                margin-bottom: 20px;
            }

            .review_description{
                word-wrap: break-word;
                height: 100%;
            }

            .review_buttons{
                display: flex;
                flex-direction: row;
                align-items: center;
                align-self: flex-end;
                padding: 5;

                .review_delete{
                    color: $red;
                    font-size: 1.6rem;
                    margin-left: 20px;
                    cursor: pointer;
                }
            }
        }
    }
}

@media screen and (max-width: 750px) {
    #review{
        .review_card{
            display: flex;
            flex-direction: column;

            .review_relate_container, .review_content{
                width: 100%;
            }

            .review_content{
                padding: 20px 20px 5px 20px;
            }
        }
    }
}