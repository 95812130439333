#navbar{
    position: fixed;
    display: flex;
    flex-direction: column;
    z-index: 10;
    top: 0;
    left: 0;
    width: 20vw;
    height: 100vh;
    background-color: $matte-black-1;
    color: $white;

    .navbar_bottom{
        display: flex;
        flex-direction: row;
        height: auto;
        align-items: center;

        #navbar_setting{
            color: $white;
            font-size: 1.4rem;
            margin-left: 20px;
            margin-top: 40px;
            margin-bottom: 20px;
            cursor: pointer;
    
            &:hover{
                color: $light-blue;
            }
        }


        #navbar_logout{
            color: $white;
            font-size: 1.4rem;
            margin-left: 30px;
            margin-top: 30px;
            cursor: pointer;
            margin-bottom: 20px;

            &:hover{
                color: $red;
            }
        }
    }

    #navbar_mobile{
        display: none;
    }

    #navbar_title{
        cursor: pointer;
        color: $white;
        padding: 30px;
        margin-bottom: 1vh;
    }

    #navabr_links{
        display: flex;
        flex-direction: column;
        height: 93vh;
        overflow-y: auto;
    }

    .navbar_item{
        display: flex;
        flex-direction: column;
    }
    
    .navbar_toggle_link{
        width: 100%;
        padding: 10px 40px 10px 40px;
        font-size: 20px;
        font-weight: 700;
        //cursor: pointer;
        background-color: $matte-black-4;
    }

    .navbar_dropdown_wrapper{
        display: flex;
        flex-direction: column;
        color: $matte-black-3;
    }

    .navbar_link{
        width: 100%;
        padding: 10px 40px 10px 40px;
        font-size: 16px;
        cursor: pointer;
        font-weight: 700;

        &[data-active="false"]{
            color: $light-gray-1;
            background-color: $matte-black-3;

            &:hover{
                background-color: rgba(67, 66, 66, 0.2);
            }
        }

        &[data-active="active"]{
            color: $white;
            background-color: $light-blue;
        }

    }
}

//mobile view
@media screen and (max-width: 750px) {
    #navbar{
        width: 0vw;
        height: 0vh;
        background: transparent;

        #navbar_title{
            display: none;
        }

        #navbar_mobile{
            position: fixed;
            z-index: 10;
            top: 0;
            left: 0;
            width: 100vw;
            height: 8vh;
            background-color: $matte-black-1;
            display: flex;
            flex-direction: row;
            text-align: center;
            align-items: center;
            padding: 0 20px 0 20px;

            #navbar_mobile_menu{
                width: 20px;
                font-size: 20px;
                justify-self: flex-start;
            }

            .navbar_mobile_title_wrap{
                width: 100%;
            }

            #navbar_mobile_title{
                color: $white;
                padding-right: 20px;
                width: 100%;
            }
        }
        

        #navabr_links{
            position: fixed;
            z-index: 10;
            background-color: $matte-black-1;
            width: 100vw;
            height: 92vh;
            margin-top: 8vh;
            padding-top: 4vh;
            transition: all 0.5s ease;

            &[data-active="false"]{
                transform: translateX(-100vw);
            }

            &[data-active="true"]{
                transform: translateX(0);
            }
        }
    }
}