#socialMedia{
    .socialMedia_title{
        margin-bottom: 40px;
    }

    .socialMedia_wrapper{
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;

        .input{
            width: 60%;
            margin-top: 8px;
        }
    }

    .button{
        margin-top: 10px;
    }
}

@media screen and (max-width: 750px) {
    #socialMedia{
        .socialMedia_wrapper{
            .input{
                width: 90%;
            }
        }
    }
}