#settings{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    color: $light-gray;

    .settings_title{
        color: #000;
        margin-bottom: 30px;
    }

    .settings_email_wrapper{
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        .settings_email_title{
            margin-right: 7px;
        }
    }

    .settings_password_wrapper{
        display: flex;
        flex-direction: row;
        margin-top: 10px;

        .input{
            margin-right: 10px;
        }

        #settings_btn{
            margin-left: 10px;
        }
    }

}

@media screen and (max-width: 750px) {
    #settings{
        .settings_password_wrapper{
            display: flex;
            flex-direction: column;
            width: 100%;

            .input{
                margin-right: 0px;
                margin-bottom: 10px;
            }

            #settings_btn{
                margin-left: 0px;
            }
        }
    }
}