.deletePopup{
    background-color: rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;

    &[data-active="false"]{
        display: none;
    }

    .deletePopup_container{
        min-width: 20vw;
        min-height: 20vh;
        background-color: #fff;
        border-radius: 5px;
        padding: 30px;

        .top{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 20px;

            .exit_icon{
                color: $red;
                cursor: pointer;
            }
        }


        .button_wrap{
            display: flex;
            flex-direction: row;
            gap: 20px;
            justify-content: flex-end;
        }
    }
}