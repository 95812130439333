#login{
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    display:   grid;
    grid-template-columns: 50% 50%;
    padding: 50px 200px 50px 200px;
    transition: all 1s ease;
    
    #login_img{
        width: 130%;
    }

    .login_grid_item{
        display: flex;
        flex-direction: column;
        justify-content: center;

        &:nth-child(1){
            align-items: flex-start;
        }
        &:nth-child(2){
            align-items: center;
        }
    }

    #login_title{
        font-size: 3rem;
        margin-bottom: 30px;
        margin-top: -30px;
    }

    .login_input{
        padding: 10px 5px 10px 15px;
        border: 2px solid $light-gray-1;
        outline: none;
        width: 300px;
        border-radius: 3px;
        margin-bottom: 20px;

        &:focus{
            border: 2px solid $light-blue;
        }
    }

    #login_btn_wrap{
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        height: 50px;
    }

    #login_btn{
        outline: none;
        padding: 10px 50px 10px 50px;
        background-color: $light-blue;
        border: 0;
        cursor: pointer;
        color: $white;
        font-size: 17px;
        font-weight: 500;
        margin-top: 20px;
        border-radius: 5px;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
        transition: all 0.1s ease;        

        &:active{
            transform: scale(0.97);
        }
    }
}

@media screen and (max-width: 750px) {
    #login{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;

        .login_input{
            width: 80vw;
        }

        .login_grid_item{
            &:nth-child(2){
                display: none;
            }
        }
    }
}

@media screen and (min-width: 1024px) and (max-width: 1080px) {
    #login{
        padding: 50px 100px 50px 100px;

        #login_img{
            width: 110%;
        }
        
    }
}